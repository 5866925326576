.bestiaButton {
  background: none;
  padding: 0;
}

.bestiaBg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.bestiaBg::before,
.bestiaBg::after {
  content: '';
  position: absolute;
}

.bestiaBg::before {
  width: 110%;
  height: 0;
  padding-bottom: 110%;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.bestiaButton:hover .bestiaBg::before {
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.bestiaBg::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.bestiaButton:hover .bestiaBg::after {
  opacity: 1;
  transition-duration: 0.01s;
  transition-delay: 0.3s;
}

.bestiaButton span {
  display: block;
  padding: 0.5rem 1rem;
  position: relative;
}
